<template>
  <div>

    <b-card
      v-if="ability().can('read', 'CampaignDetailReport')"
      no-body
      class="custom-border-top-1px"
    >
      <b-card-header>
        <b-badge
          variant="light-primary"
          class="font-small-4 px-1 py-50"
        >{{ campainName }}
        </b-badge>
      </b-card-header>

      <b-card-body>

        <div class="text-primary font-weight-bolder mb-1">
          Báo cáo hôm nay
        </div>

        <b-table
          ref="table-suggested-list-customer"
          class="position-relative th130px"
          responsive
          small
          primary-key="id"
          show-empty
          bordered
          empty-text="Không có kết quả nào"
          :items="addTotalRowReportToday(campaignReportToday, tableCampaignTodayDetail)"
          :fields="tableCampaignTodayDetail"
        >
          <template #cell(name)="data">
            <span
              class="text-primary"
            >
              {{ data.item.name }}
            </span>
          </template>

          <template #cell(called)="data">
            <span class="text-primary font-weight-bolder">
              <template v-if="data.item.counts">
                {{
                  + (data.item.counts.available || 0)
                    + (data.item.counts.available_send_another_product || 0)
                    + (data.item.counts.unavailable || 0)
                    + (data.item.counts.unavailable_send_another_product || 0)
                    + (data.item.counts.dont_pick_up || 0)
                    + (data.item.counts.not_the_owner || 0)
                    + (data.item.counts.agency || 0)
                    + (data.item.counts.cannot_checked || 0)
                }}
              </template>
            </span>
          </template>

          <template #cell(new)="data">
            <span class="text-primary">
              <template v-if="data.item.counts">
                {{ data.item.counts && data.item.counts.new }}
              </template>
            </span>
          </template>

        </b-table>

        <br>

        <div class="text-primary font-weight-bolder mb-1">
          Báo cáo tổng
        </div>

        <b-table
          ref="table-suggested-list-customer"
          class="position-relative th130px"
          responsive
          small
          primary-key="id"
          show-empty
          bordered
          empty-text="Không có kết quả nào"
          :items="addTotalRowReport(campaignReport, tableCampaignDetail)"
          :fields="tableCampaignDetail"
        >
          <template #cell(name)="data">
            <span
              class="text-primary"
            >
              {{ data.item.name }}
            </span>
          </template>

          <template #cell(called)="data">
            <span class="text-primary font-weight-bolder">
              <template v-if="data.item.counts">
                {{
                  + (data.item.counts.available || 0)
                    + (data.item.counts.available_send_another_product || 0)
                    + (data.item.counts.unavailable || 0)
                    + (data.item.counts.unavailable_send_another_product || 0)
                    + (data.item.counts.dont_pick_up || 0)
                    + (data.item.counts.not_the_owner || 0)
                    + (data.item.counts.agency || 0)
                    + (data.item.counts.cannot_checked || 0)
                }}
              </template>
            </span>
          </template>

          <template #cell(new)="data">
            <span class="text-primary">
              <template v-if="data.item.counts">
                {{ data.item.counts && data.item.counts.new }}
              </template>
            </span>
          </template>

        </b-table>

      </b-card-body>

    </b-card>

    <b-card
      no-body
      class="custom-border-top-1px"
    >
      <b-card-header>
        <b-badge
          variant="light-primary"
          class="font-small-4 px-1 py-50"
        >CÁC CĂN CẦN GỌI ({{ totalProducts }})
        </b-badge>
      </b-card-header>

      <b-card-body>

        <b-row class="fit-filter mb-2">
          <b-col
            cols="12"
            lg="6"
          >
            <!--           đã xuất-->
            <dynamic-form
              :id="'fit-filter-is_not_exported'"
              :value="fitFilter.status"
              :type="'select'"
              :label="'Trạng thái'"
              text-field="text"
              :clearable="true"
              :select-default="false"
              :options="CAMPAIGN_STATUS"
              @update-value="val => fitFilter.status = val"
            />
          </b-col>

          <b-col
            cols="12"
            lg="6"
          >
            <!--              nhân viên-->
            <dynamic-form
              :id="'fit-filter-user_id'"
              :value="fitFilter.user_id"
              :type="'select'"
              :label="'Người xác nhận'"
              :clearable="true"
              text-field="name"
              value-field="id"
              :select-default="false"
              :options="campaignReport"
              @update-value="val => fitFilter.user_id = val"
            />
          </b-col>

        </b-row>

        <b-table
          ref="table-suggested-list-customer"
          class="position-relative"
          responsive
          primary-key="id"
          show-empty
          bordered
          hover
          small
          empty-text="Không có kết quả nào"
          :items="campaignConfirm"
          :fields="tableCampaignConfirm"
          @row-clicked="handleRowClick"
        >

          <template #cell(image)="data">
            <b-media-aside class="mr-75">
              <b-avatar
                square
                size="64"
                :src="data.item.product.image ? getUrlFile(data.item.product.image, data.item.product.updated_at, showImage160.includes(data.item.product.image || '') ? '240' : '160') : ''"
                :text="data.item.product.image ? '' : 'CL'"
                @img-error="() => showImage160.push(data.item.product.image)"
              />
            </b-media-aside>
          </template>

          <template #cell(title)="data">
            <div
              class="py-50 font-small-3"
              :class="data.item.product.close_deal_type === 'close-deal' ? 'text-danger' : 'text-primary'"
            >
              <template v-if="data.item.product.close_deal_type === 'close-deal'">
                [CLOSE DEAL]
              </template>
              {{ data.item.product.title }}
            </div>
          </template>

          <template #cell(confirm_status)="data">

            <b-badge
              v-if="data.item.product"
              :class="'bg-light-' + (data.item.product.confirm_status === 'new' ? 'secondary' : data.item.product.confirm_status)"
            >
              <span :class="'text-' + (data.item.product.confirm_status === 'new' ? 'secondary' : data.item.product.confirm_status)">
                {{ (CAMPAIGN_CONFIRM_STATUS.find(item => item.value === data.item.product.confirm_status) || {}).text || '' }}
              </span>
            </b-badge>

            <div
              v-if="data.item.product"
              class="mt-50"
            >
              {{ data.item.product.confirm_result }}
            </div>
          </template>

          <template #cell(status)="data">
            <b-badge :class="'bg-light-' + (data.item.status === 'new' ? 'secondary' : data.item.status)">
              <span :class="'text-' + (data.item.status === 'new' ? 'secondary' : data.item.status)">
                {{ (CAMPAIGN_STATUS.find(item => item.value === data.item.status) || {}).text || '' }}
              </span>
            </b-badge>
          </template>

          <template #cell(price)="data">
            <div class="text-primary text-nowrap">
              <span class="font-weight-bold">
                {{ formatNumber(data.item.product.input_price) }} <small>{{ (CURRENCY.find(item => item.value === data.item.product.currency) || {text: 'usd'}).text.toUpperCase() }}</small>
              </span>
              <template v-if="data.item.product.type_of_payment === 'month'">
                <br>
                / tháng
              </template>
            </div>
          </template>

          <template #cell(ten-chu)="data">
            <div class="user-select-none">
              <div class="font-weight-bold">
                {{ data.item.product.host_name }}
              </div>
              <div>
                {{ data.item.product.host_phone1 || data.item.product.host_phone2 }}
              </div>
            </div>
          </template>

          <template #cell(actions)="data">

            <div class="product-actions-wrapper">

              <b-button
                variant="outline-primary"
                size="sm"
                class="p-50"
                @click="handleRowClick(data.item)"
              >
                <feather-icon
                  icon="ArrowUpRightIcon"
                />
              </b-button>
              <b-button
                variant="outline-success"
                size="sm"
                class="p-50"
                @click="handleOpentModalUpdateCampaign(data.item)"
              >
                <feather-icon
                  icon="EditIcon"
                />
              </b-button>
            </div>
          </template>

        </b-table>

        <div class="mb-3">
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start mb-1 mb-md-0"
            >
              <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>mỗi trang</label>
            </b-col>

            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalProducts"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>

      </b-card-body>

    </b-card>

    <b-modal
      id="modal-update-campaign"
      ref="modal-update-campaign"
      cancel-title="Hủy"
      ok-title="Cập nhật"
      :no-close-on-backdrop="true"
      centered
      header-text-variant="primary"
      :title="'Cập nhật sản phẩm chiến dịch #' + (currentModalCampaignData.product && currentModalCampaignData.product.id)"
      @ok="handleUpdateCampaign(updateCampaignData)"
    >
      <b-row class="mb-1">
        <b-col cols="12">
          <dynamic-form
            :id="'campaign-status-update-' + currentModalCampaignData.id"
            :value="updateCampaignData.status"
            :type="'select'"
            :label="'Trạng thái mới'"
            :select-default="false"
            :options="CAMPAIGN_STATUS"
            @update-value="val => updateCampaignData.status = val"
          />
        </b-col>
        <b-col cols="12">
          <dynamic-form
            :id="'campaign-status-update-' + currentModalCampaignData.id"
            :value="updateCampaignData.result"
            :type="'textarea'"
            :label="'Kết quả'"
            :select-default="false"
            :options="CAMPAIGN_STATUS"
            @update-value="val => updateCampaignData.result = val"
          />
        </b-col>
      </b-row>
    </b-modal>

    <actions
      :buttons="actionsButton"
      @click="handleActionsButton"
    />
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BBadge,
  BTable,
  BRow,
  BCol,
  BPagination,
  BCardBody,
  BCardText,
  BMediaAside,
  BAvatar,
  BDropdown,
  BDropdownItem, BFormTextarea, BButton, BFormInput, BInputGroup,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import campaigntoreModule from '@/views/campaign/campaignStoreModule'
import useCampaignDetail from '@/views/campaign/detail/useCampaignDetail'
import Actions from '@core/components/fixed-button/Fixed-Right-Button.vue'
import vSelect from 'vue-select'
import DynamicForm from '@core/dynamic-form/DynamicForm.vue'
import http from '@/global/http'
import Nav from '@/views/components/nav/Nav.vue'
import ability from '@/libs/acl/ability'
import { subject } from '@casl/ability'

export default {
  components: {
    BInputGroup,
    BFormInput,
    BButton,
    DynamicForm,
    BFormTextarea,
    BDropdownItem,
    BDropdown,
    BAvatar,
    BMediaAside,
    BCard,
    BCardHeader,
    BBadge,
    Actions,
    BTable,
    BRow,
    BCol,
    BPagination,
    vSelect,
    BCardBody,
  },
  props: {
    value: {
      type: Object,
      require: true,
      default: () => {
      },
    },
  },
  data() {
    return {
      ability,
      subject,
    }
  },
  computed: {
    actionsButton() {
      const validActions = []
      // if (ability()
      //   .can('edit', subject('ProductHired', this.product))) {
      //   validActions.push(this.ACTIONS_BUTTON.EDIT, this.ACTIONS_BUTTON.REFRESH, this.product.close_deal_type === 'active' ? this.ACTIONS_BUTTON.CLOSE : this.ACTIONS_BUTTON.OPEN)
      // }
      return validActions
    },
  },
  created() {
    this.routeParams = this.$route.params
    this.fetchCampaignsReport()
    this.fetchCampaignsReportToday()
    this.fetchCampaignsConfirm()
  },
  methods: {
    addTotalRowReportToday(list = [], fields = []) {
      if (!list.length || !fields.length) return list
      return [...list, {
        name: 'Tổng',
        called: list.reduce((p, c) => p + c.called, 0),
        counts: {
          available: list.reduce((p, c) => p + parseFloat(c.counts.available || 0), 0),
          available_send_another_product: list.reduce((p, c) => p + parseFloat(c.counts.available_send_another_product || 0), 0),
          unavailable: list.reduce((p, c) => p + parseFloat(c.counts.unavailable || 0), 0),
          unavailable_send_another_product: list.reduce((p, c) => p + parseFloat(c.counts.unavailable_send_another_product || 0), 0),
          dont_pick_up: list.reduce((p, c) => p + parseFloat(c.counts.dont_pick_up || 0), 0),
          not_the_owner: list.reduce((p, c) => p + parseFloat(c.counts.not_the_owner || 0), 0),
          agency: list.reduce((p, c) => p + parseFloat(c.counts.agency || 0), 0),
          cannot_checked: list.reduce((p, c) => p + parseFloat(c.counts.cannot_checked || 0), 0),
        },
      }]
    },
    addTotalRowReport(list = [], fields = []) {
      if (!list.length || !fields.length) return list
      return [...list, {
        name: 'Tổng',
        called: list.reduce((p, c) => p + c.called, 0),
        counts: {
          new: list.reduce((p, c) => p + parseFloat(c.counts.new || 0), 0),
          available: list.reduce((p, c) => p + parseFloat(c.counts.available || 0), 0),
          available_send_another_product: list.reduce((p, c) => p + parseFloat(c.counts.available_send_another_product || 0), 0),
          unavailable: list.reduce((p, c) => p + parseFloat(c.counts.unavailable || 0), 0),
          unavailable_send_another_product: list.reduce((p, c) => p + parseFloat(c.counts.unavailable_send_another_product || 0), 0),
          dont_pick_up: list.reduce((p, c) => p + parseFloat(c.counts.dont_pick_up || 0), 0),
          not_the_owner: list.reduce((p, c) => p + parseFloat(c.counts.not_the_owner || 0), 0),
          agency: list.reduce((p, c) => p + parseFloat(c.counts.agency || 0), 0),
          cannot_checked: list.reduce((p, c) => p + parseFloat(c.counts.cannot_checked || 0), 0),
        },
      }]
    },
    gotoUserDetail(id) {
      this.$router.push({ name: 'user-detail', params: { id } })
    },
    handleActionsButton(btn) {
      if (btn.value === this.ACTIONS_BUTTON.CLOSE.value) {
        if (this.productMenuType.toString() === 'sell') {
          this.handleCloseDeal()
        }
        if (this.productMenuType.toString() === 'hired') {
          this.showModal('modal-close-deal-hired-product')
        }
      }
      if (btn.value === this.ACTIONS_BUTTON.OPEN.value) {
        this.$call(this.openDealProduct(this.product.id), true)
          .then(res => {
            Object.assign(this.product, res)
          })
      }
      if (btn.value === this.ACTIONS_BUTTON.EDIT.value) {
        this.$router.push({
          name: `product-${this.productMenuType}-edit`,
          params: { id: this.$route.params.id },
        })
      }
      if (btn.value === this.ACTIONS_BUTTON.REFRESH.value) {
        this.$call(this.refreshProduct(this.product.id), true)
          .then(() => {
            this.product.updated_at = new Date()
          })
      }
    },
    handleOpentModalUpdateCampaign(data) {
      this.currentModalCampaignData = data
      this.showModal('modal-update-campaign')
    },
    handleCloseDeal() {
      this.$call(this.closeDealProduct({
        id: this.product.id,
        expire_contract_date: this.product.expire_contract_date,
      }), true)
        .then(res => {
          Object.assign(this.product, res)
        })
    },
    handleRowClick(row) {
      const route = this.$router.resolve({
        name: `product-${row.product.formality === 'cho-thue' ? 'hired' : 'sell'}-detail`,
        params: { id: row.product.id },
      })
      window.open(route.href)
    },
  },
  setup() {
    const CAMPAIGN_STORE_MODULE_NAME = 'campaign'
    if (!store.hasModule(CAMPAIGN_STORE_MODULE_NAME)) store.registerModule(CAMPAIGN_STORE_MODULE_NAME, campaigntoreModule)
    onUnmounted(() => {
      if (store.hasModule(CAMPAIGN_STORE_MODULE_NAME)) store.unregisterModule(CAMPAIGN_STORE_MODULE_NAME)
    })

    return {
      ...useCampaignDetail(),
    }
  },
}
</script>

<style lang="scss">

.product-badge {
  position: absolute;
  right: .25rem;
  font-size: .7rem !important;

  &.is-exported {
    top: .25rem;
  }
}
.product-actions-wrapper {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;

  > * {
    padding: .25rem !important;
  }
}
</style>
